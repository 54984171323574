import { mixed } from 'yup'

import { NormalizedLocation } from './types'

export const buildCardLocationsSchema = ({ isCardEnabled }: { isCardEnabled: boolean }) => {
  const baseSchema = mixed<NormalizedLocation[]>().default([])

  if (!isCardEnabled) {
    return { locations: baseSchema }
  }

  return {
    locations: baseSchema,
  }
}
