<template>
  <Card title="Locations" opened collapse withDivider>
    <FormError :id="getId(`locations`)" pb="2" />
    <Transitions name="slide">
      <Box v-if="loading">
        <Skeleton height="411px" />
      </Box>
      <ErrorState v-else-if="error" :error="error" @retry="request()" />
      <Box v-else>
        <SelectPanel
          :loading="isListLoading(locationType)"
          :empty="isListEmpty(locationType) && !getListError(locationType)"
          :emptyIcon="getListPlaceholderIcon(locationType)"
          :placeholder="getListPlaceholder(locationType, tabs[locationType].label)"
          :listKey="locationType"
        >
          <template #search>
            <SelectPanelInput
              :key="locationType"
              v-model="searchQueries[locationType]"
              :placeholder="`Search ${tabs[locationType].label}`"
            />
          </template>

          <template #tabs>
            <Tabs v-model:active="locationType" variant="text" size="md">
              <TabsItem v-for="tab in tabs" :key="tab.value" :value="tab.value">
                {{ tab.label }}
              </TabsItem>
            </Tabs>
          </template>

          <template v-if="!isListLoading(locationType) || getListError(locationType)" #items>
            <ErrorState
              v-if="getListError(locationType)"
              :error="getListError(locationType)"
              @retry="requestList(locationType)"
            />
            <SelectPanelVirtualScroller
              v-else
              v-slot="{ item: location }"
              :items="locationLists[locationType]"
              keyField="id"
            >
              <SelectPanelItem
                :modelValue="isLocationSelected(location)"
                :chip="getLocationChip(location)"
                :label="location.name"
                :searchQuery="searchQueries[locationType]"
                singleLine
                @update:modelValue="setLocationSelected(location, $event)"
              />
            </SelectPanelVirtualScroller>
          </template>

          <template #selectedItemsTitle>
            Selected Locations
          </template>

          <template v-if="locations.length > 0" #selectedItems>
            <template v-for="{ value, label, group } in locationsGroupedArray" :key="value">
              <SelectPanelItemGroup :title="label">
                <template v-if="value === LocationSubType.POSTAL_CODE" #action>
                  <Button size="xs" @click="clearGroup(value)">
                    Clear
                  </Button>
                </template>
                <SelectPanelSelectedItem
                  v-for="location of group.pageItems"
                  :key="location.id"
                  :chip="getLocationChip(location)"
                  :label="location.name"
                  :enabled="isLocationEnabled(location)"
                  :noToggle="isListNoToggle(value)"
                  @update:enabled="setLocationEnabled(location, $event)"
                  @remove="setLocationSelected(location, false)"
                />
                <Box flex justify="end">
                  <Pagination
                    v-if="group.pageQty > 1"
                    v-model="group.pageNumber"
                    :pagesTotal="group.pageQty"
                  />
                </Box>
              </SelectPanelItemGroup>
            </template>
          </template>
        </SelectPanel>
      </Box>
    </Transitions>
  </Card>
</template>

<script setup lang="ts">
import {
  Box,
  Button,
  Card, ErrorState, FormError,
  Pagination, SelectOptionType,
  SelectPanel,
  SelectPanelInput,
  SelectPanelItem,
  SelectPanelItemGroup,
  SelectPanelSelectedItem,
  SelectPanelVirtualScroller,
  Skeleton,
  Tabs,
  TabsItem, Transitions,
} from '@lasso/luikit'
import { LocationSubType } from '@lasso/api-activation/activation'

import { objValues } from '@lasso/shared/utils'

import { computed } from 'vue'

import { useCardLocations } from './useCardLocations'

const {
  locations,
  locationsGrouped,
  locationLists,
  searchQueries,
  locationType,
  loading,
  error,
  request,
  isListLoading,
  isListEmpty,
  isListNoToggle,
  getListPlaceholder,
  getListPlaceholderIcon,
  getListError,
  requestList,
  isLocationSelected,
  setLocationSelected,
  isLocationEnabled,
  clearLocations,
  setLocationEnabled,
  getLocationChip,
  getId,
} = useCardLocations()!

const tabs: Record<LocationSubType, SelectOptionType<LocationSubType>> = {
  [LocationSubType.STATE]: {
    value: LocationSubType.STATE,
    label: 'State',
  },
  [LocationSubType.CITY]: {
    value: LocationSubType.CITY,
    label: 'City',
  },
  [LocationSubType.DMA]: {
    value: LocationSubType.DMA,
    label: 'DMA',
  },
  [LocationSubType.POSTAL_CODE]: {
    value: LocationSubType.POSTAL_CODE,
    label: 'Zip Code',
  },
  [LocationSubType.LOCATION_GROUP]: {
    value: LocationSubType.LOCATION_GROUP,
    label: 'Location Group',
  },
} satisfies { [K in LocationSubType]: SelectOptionType<K> }

const locationsGroupedArray = computed(() => {
  return objValues(tabs)
    .map(tab => ({ ...tab, group: locationsGrouped[tab.value] }))
    .filter(({ group }) => group.totalItems > 0)
})

const clearGroup = (locationType: LocationSubType) => {
  clearLocations(locationType)
  searchQueries[locationType] = ''
}
</script>
