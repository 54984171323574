import { LocationSubType, useActivationApi } from '@lasso/api-activation/activation'
import {
  arrayIncludes,
  objFromEntries,
  objValues,
} from '@lasso/shared/utils'
import { useApi, usePaginatedArray } from '@lasso/shared/hooks'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, reactive, toValue } from 'vue'

import { NormalizedLocation } from './types'
import { buildLocationsMap } from './utils'

const typesWithLinkedEnabledState = [
  LocationSubType.STATE,
  LocationSubType.CITY,
  LocationSubType.DMA,
  LocationSubType.POSTAL_CODE,
] as const

export const useSelectedLocations = ({ isCardEnabled, locations, adGroupId }: {
  isCardEnabled: RefOrGetter<boolean>

  locations: Ref<NormalizedLocation[]>
  adGroupId: Readonly<Ref<number>>
}) => {
  const locationsGrouped = reactive(
    objFromEntries(
      objValues(LocationSubType).map((type) => {
        return [
          type,
          usePaginatedArray({
            items: computed(() => locations.value.filter(location => location.type === type).reverse()),
            pageSize: 100,
          }),
        ]
      }),
    ),
  ) satisfies Record<LocationSubType, unknown>

  const { loading, onData, error, request } = useApi(
    useActivationApi().getAdGroupLocations,
    () => {
      return toValue(isCardEnabled)
        ? [{ adGroupId: adGroupId.value, paging: { pageSize: 99999, skip: 0 } }]
        : null
    },
    { refetch: true },
  )

  const isMatchingLocations = (a: NormalizedLocation, b: NormalizedLocation) => {
    return a.id === b.id && a.type === b.type
  }

  const locationsByTypeById = computed(() => buildLocationsMap(locations.value))

  const isLocationSelected = (location: NormalizedLocation): boolean => {
    return Boolean(locationsByTypeById.value.get(location.type)?.has(location.id))
  }

  const getLocationTypeEnabled = (type: LocationSubType) => {
    if (arrayIncludes(typesWithLinkedEnabledState, type)) {
      const firstMatching = locations.value.find(location => location.type === type)

      return firstMatching?.enabled ?? true
    }
    else {
      return true
    }
  }

  const setLocationsSelected = (locationToToggle: NormalizedLocation[], value: boolean) => {
    if (value) {
      const locationsToSelect = locationToToggle.filter(location => !isLocationSelected(location))
      if (locationsToSelect.length > 0) {
        locations.value = [
          ...locations.value,
          ...locationsToSelect.map(location => ({
            ...location,
            enabled: getLocationTypeEnabled(location.type),
          })),
        ]
      }
    }
    else {
      locations.value = locations.value.filter((selectedLocation) => {
        return locationToToggle.every(location => !isMatchingLocations(selectedLocation, location))
      })
    }
  }

  const setLocationSelected = (location: NormalizedLocation, value: boolean) => {
    setLocationsSelected([location], value)
  }

  const isLocationEnabled = (location: NormalizedLocation): boolean => {
    return location.enabled
  }

  const setLocationEnabled = (location: NormalizedLocation, enabled: boolean) => {
    locations.value = locations.value.map((selectedLocation) => {
      const isMatching = arrayIncludes(typesWithLinkedEnabledState, location.type)
        ? selectedLocation.type === location.type
        : isMatchingLocations(selectedLocation, location)

      return isMatching ? { ...selectedLocation, enabled } : selectedLocation
    })
  }

  const clearLocations = (locationType: LocationSubType) => {
    locations.value = locations.value.filter(location => location.type !== locationType)
  }

  const getLocationChip = (location: NormalizedLocation): string | undefined => {
    return location.type === LocationSubType.LOCATION_GROUP ? String(location.id) : undefined
  }

  return {
    locationsGrouped,

    loading,
    error,
    request,
    onData,

    isLocationSelected,
    setLocationSelected,
    setLocationsSelected,
    isLocationEnabled,
    setLocationEnabled,
    clearLocations,
    getLocationChip,
  }
}
