import { LocationGroup } from '@lasso/api-activation/activation/legacy'
import { LocationItem, LocationShortInfo, LocationSubType } from '@lasso/api-activation/activation'

import { NormalizedLocation } from './types'

export type NormalizeLocationParam = {
  type: LocationSubType
  location: LocationItem | LocationGroup
}

export const normalizeListLocation = (param: NormalizeLocationParam): NormalizedLocation => {
  return {
    id: param.location.id ?? 0,
    name: param.location.name ?? '',
    type: param.type,
    enabled: true,
  }
}

export const normalizeSelectedLocation = (location: LocationShortInfo): NormalizedLocation => {
  return {
    // TODO: don't send negative ids from backend, what are you even doing
    id: Math.abs(location.locationId ?? 0),
    name: location.locationName ?? '',
    type: location.subType!,
    enabled: !(location.isExcluded ?? false),
  }
}

export const buildLocationsMap = (locations: NormalizedLocation[]): Map<LocationSubType, Map<number, NormalizedLocation>> => {
  const locationsByTypeById: Map<LocationSubType, Map<number, NormalizedLocation>> = new Map()

  for (const location of locations) {
    const { type, id } = location

    if (!locationsByTypeById.has(type)) {
      locationsByTypeById.set(type, new Map())
    }

    locationsByTypeById.get(type)!.set(id, location)
  }

  return locationsByTypeById
}
